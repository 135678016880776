<template>
  <!-- 智慧工业 -->
  <Waitting></Waitting>
</template>

<script>
import Waitting from '@/components/common/Waitting';
export default {
  name: '',
  data() {
    return {
       
    }
  },
  components: {
    Waitting
  },
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  
</style>